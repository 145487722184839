//@ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import Client from "shopify-buy";
import { getLocalState, setLocalState } from "../helper/local-storage";
import { CART_PRODUCTS, LocalCartContext } from "./local-cart-provider";

import fetch from 'isomorphic-fetch'

export const CartContext = React.createContext();

const client = Client.buildClient(
  {
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN, // Load from env
  domain: process.env.GATSBY_SHOP_DOMAIN, // Load from env
  },
  fetch
);

const CartProvider = ({ children }) => {
  let initialStoreState = {
    client,
    updating: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
  };

  const [store, updateStore] = useState(initialStoreState);
  //let isRemoved = false
  //const [initialize, setIntialize] = useState(false);

  const { clearCart } = useContext(
    LocalCartContext
  );

  const [noteText, setNoteText] = useState('')
  const isBrowser = typeof window !== 'undefined'

  useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem('shopify_checkout_id')
        : null
      /* 
      const setCheckoutInState = checkout => {
        if (isBrowser) {
          localStorage.setItem('shopify_checkout_id', checkout.id)
        }
        updateStore(prevState => {
          return { ...prevState, checkout }
        })
      }
      const createNewCheckout = () => store.client.checkout.create()
      */
      const fetchCheckout = id => store.client.checkout.fetch(id)
      if (existingCheckoutID) {
        try {
          const checkout = await fetchCheckout(existingCheckoutID)
          // Make sure this cart hasn’t already been purchased.
          if (checkout.completedAt) {
            clearCart()
          }
        } catch (e) {
          //localStorage.setItem('shopify_checkout_id', null)
        }
      }
      // const newCheckout = await createNewCheckout()
      // if (!isRemoved) {
      //  setCheckoutInState(newCheckout)
      //}
    }
    initializeCheckout();
  },[] );
  /*
  useEffect(
    () => () => {
      isRemoved = true
    },
    []
  )
  */

  return (
    <CartContext.Provider
      value={{
        store,
        noteText,
        setNoteText,
        addToCartAndCheckout: async () => {
          const createNewCheckout = () => store.client.checkout.create()
          const checkout = await createNewCheckout()
          
          const setCheckoutInState = checkout => {
            if (isBrowser) {
              localStorage.setItem('shopify_checkout_id', checkout.id)
            }
            updateStore(prevState => {
              return { ...prevState, checkout }
            })
          }
          
          setCheckoutInState(checkout)
          const { client } = store;
          const checkoutId = checkout.id;
          
          updateStore((prevState) => {
            return { ...prevState, updating: true };
          });
          const products = getLocalState(CART_PRODUCTS);
          
          const lineItemsToUpdate = [];

          products.forEach((product) => {
            lineItemsToUpdate.push({
              variantId: product.variantId,
              quantity: product.quantity,
            });
          });
          //let windowReference = window.open();
          const message = noteText
          const input = {customAttributes: [{key: "Gift Note", value: message}]};
          client.checkout.updateAttributes(checkoutId, input)
          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then((checkout) => {
              updateStore((prevState) => {
                return {
                  ...prevState,
                  checkout,
                  updating: false,
                };
              });
              window.location.href = checkout.webUrl;
            }
          )
        },
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;