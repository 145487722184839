// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cge-tsx": () => import("./../../../src/pages/cge.tsx" /* webpackChunkName: "component---src-pages-cge-tsx" */),
  "component---src-pages-cgfood-tsx": () => import("./../../../src/pages/cgfood.tsx" /* webpackChunkName: "component---src-pages-cgfood-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-farm-tsx": () => import("./../../../src/pages/farm.tsx" /* webpackChunkName: "component---src-pages-farm-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-stories-exclude-tsx": () => import("./../../../src/pages/stories.exclude.tsx" /* webpackChunkName: "component---src-pages-stories-exclude-tsx" */),
  "component---src-pages-thankyou-tsx": () => import("./../../../src/pages/thankyou.tsx" /* webpackChunkName: "component---src-pages-thankyou-tsx" */),
  "component---src-pages-venue-tsx": () => import("./../../../src/pages/venue.tsx" /* webpackChunkName: "component---src-pages-venue-tsx" */),
  "component---src-templates-all-products-tsx": () => import("./../../../src/templates/all-products.tsx" /* webpackChunkName: "component---src-templates-all-products-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-info-page-template-tsx": () => import("./../../../src/templates/info-page-template.tsx" /* webpackChunkName: "component---src-templates-info-page-template-tsx" */),
  "component---src-templates-minimal-category-page-tsx": () => import("./../../../src/templates/minimal-category-page.tsx" /* webpackChunkName: "component---src-templates-minimal-category-page-tsx" */),
  "component---src-templates-product-page-tsx": () => import("./../../../src/templates/product-page.tsx" /* webpackChunkName: "component---src-templates-product-page-tsx" */),
  "component---src-templates-story-archive-tsx": () => import("./../../../src/templates/story-archive.tsx" /* webpackChunkName: "component---src-templates-story-archive-tsx" */)
}

