
const theme = {
  breakpoints: ["768px", "1024px", "1200px", "1920px"],
  colors: {
    black: "#000000",
    primary: "#292929",
    text: "#5A5A5A",
    menu: "#757575",
    darkgray: "#999999",
    muted: "#E6E6E6",
    lightgray: "#F3F3F3",
    lightergray: "#F9F9F9",
    background: "#fff",
    white: "#ffffff",
    facebook: "#3b5998",
    twitter: "#0084b4",
    youtube: "#c4302b",
    github: "#211F1F",
    pinterest: "#E60023",
    instagram: "#cd486b",
    linkedin: "#0e76a8",
    badge: "#FF5A5F",
    // CG colors
    cgMustard: "#F69D0D",
    cgLime: "#B6BE0D",
    cgFushia: "#AE1485",
    cgTeal: "#054752",
    cgGold: "#D79A2B",
    primaryGrey: "#878787",
    primaryDarkGrey: "#212B36",
    headingColor: "#054752",
    subHeadingColor: "#535F61",
    cgGray: "#F5F5F5",
    cgBeige: "#F3F3F3",
  },
  fonts: {
    body: "Montserrat, sans-serif",
    heading: "Lora, serif",
  },
  fontSizes: [13, 15, 19, 20, 30],
  lineHeights: {
    body: "initial",
    heading: "initial",
  },
  fontWeights: {
    body: 400,
    heading: 700,
  },
  sizes: {
    container: "1240px",
  },
  buttons: {
    primary: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      minWidth: [130, 172],
      minHeight: [40, 50],
      borderRadius: 6,
      fontWeight: 700,
      textTransform: "capitalize",
      transition: "opacity 0.2s ease",
      svg: {
        width: [16, 18],
        height: [16, 18],
        marginLeft: 10,
      },
      ":focus": {
        outline: "none",
      },
      ":hover": {
        opacity: 0.9,
      },
    },
    none: {

    },
    submit: {
        color: "#535F61",
        width: '100px',
        font: "600 22px 'Montserrat', Sans-serif",
        fontSize: '20px',
        border: "none",
        paddingBottom: "3px",
        borderRadius: 0,
      ":focus": {
        outline: "none",
      },
    },
    white: {
      color: "primary",
      backgroundColor: "white",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      minWidth: [130, 172],
      minHeight: [40, 50],
      borderRadius: 6,
      fontWeight: 700,
      textTransform: "capitalize",
      transition: "all 0.2s ease",
      svg: {
        width: [16, 18],
        height: [16, 18],
        marginLeft: 10,
      },
      ":focus": {
        outline: "none",
      },
      ":hover": {
        backgroundColor: "lightgray",
      },
    },
    badge: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: 6,
      minWidth: 38,
      minHeight: 28,
      backgroundColor: "lightgray",
      padding: 0,
      transition: "background-color 0.2s ease",
      ":focus": {
        outline: "none",
      },
      ":hover": {
        backgroundColor: "muted",
      },
    },
    text: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      borderRadius: 6,
      minWidth: 48,
      minHeight: 48,
      color: "primaryDarkGrey",
      backgroundColor: "transparent",
      padding: 0,
      svg: {
        width: 30,
        height: 30,
      },
      ":focus": {
        outline: "none",
      },
    },
  },
  layout: {
    container: {
      paddingX: "20px",
    },
  },
};
export default theme
