import React, { useState, createContext, useContext } from "react"
import { LocalCartContext } from "./local-cart-provider";
import useFreezeBodyScroll from "../hooks/useFreezeBodyScroll";

export const ProductContext = createContext()

const ProductProvider = ({ children }) => {
  const [toggleModal, setToggleModal] = useState(false);
  const [currentVariant, setCurrentVariant] = useState({});
  const { add } = useContext(LocalCartContext);

  useFreezeBodyScroll(toggleModal);

  const addVariantToCart = (productVariant) => {
    const isParentVariant = productVariant.variants?.length > 1 && !productVariant.isVariant

    if (!productVariant.available) {
      return false;
    }
    const item = {
      title: productVariant.title,
      thumbnail:
        productVariant?.image?.gatsbyImageData,
      quantity: 1,
      price: productVariant.price,
      variantId: productVariant.shopifyId,
      variantTitle: isParentVariant ? productVariant.variants[0].title : productVariant.subtitle
    }
    add(item);
    setToggleModal(true)
    setTimeout(
      function () {
        setToggleModal(false)
      }.bind(this),
      7000
    )
  };

  return (
    <ProductContext.Provider
      value={{
        addVariantToCart,
        currentVariant,
        setCurrentVariant,
        toggleModal,
        setToggleModal
      }}
    >
      {children}
    </ProductContext.Provider>
  )
}
export default ProductProvider;